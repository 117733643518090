// import logo from './logo.svg';
import './styles/App.scss'
import { Grid, Cell } from 'react-foundation'
import React, { useState, useEffect } from 'react'
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import ModalVideo from 'react-modal-video'

gsap.registerPlugin(ScrollToPlugin);

function App() {
  const [splashHidden, setSplashHidden] = useState(false)
  const [isOpen, setOpen] = useState(false)

  function hideSplash() {
    console.log('hideSplash')
    setSplashHidden(true)
  }

  const executeScroll = (section) => {
    gsap.to(window, {duration: 1, scrollTo:{y:`#${section}`, offsetY: 0}});
  }

  useEffect(() => {
    if (splashHidden === true) {
      document.body.classList.remove('lock');      
    } else {    
      window.scrollTo(0, 0);
      window.history.scrollRestoration = "manual"
      document.body.classList.add('lock');
      console.log('scroll to top')
    }
  }, [splashHidden])

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://mintplex.xyz/embeds/v2/embed1155.js"
    script.defer = true
    script.dataset.uuid = "7f4b659b-aa17-4cf6-8571-6e0ecab6a5fd"

    document.body.appendChild(script)

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <img src={'animus-logo-white.webp'} className="App-logo" alt="logo" />

        <nav className="App-navigation">
          <li onClick={() => executeScroll('about')} className="App-navigation__link">About</li>
          <li onClick={() => executeScroll('pass')} className="App-navigation__link">Pass</li>
          <li onClick={() => executeScroll('merch')} className="App-navigation__link">Merch</li>
          <li onClick={() => executeScroll('imaginari')} className="App-navigation__link">Imaginari</li>
          <li onClick={() => executeScroll('team')} className="App-navigation__link">Team</li>
          <li onClick={() => executeScroll('mint')} className="App-navigation__link">
            <button className="nav-mint__button" onClick={hideSplash}>
              <span>Mint</span>
            </button>
          </li>
        </nav>
      </header>
      <div className="App-header-shadow"></div>
      <section className={`App-splash App-splash-show--${splashHidden}`} style={{ backgroundImage: `url('animus-bg-static.jpg')` }}>
        <video className="App-splash__video" autoPlay loop muted playsInline src="https://player.vimeo.com/progressive_redirect/playback/746622908/rendition/1080p/file.mp4?loc=external&signature=dc8737153cb9a8b431910eb65014e1c1ece54347353fda0f9bf4621444473add" type="video/mp4"></video>
        <button className="splash-enter__button" onClick={hideSplash}>
          <span>Enter</span>
          <div className="splash-enter__bg" style={{ backgroundImage: `url('button-bg.jpg')` }}></div>
        </button>
        <div className={`App-intro-info App-intro-info--${splashHidden}`}>
          <h1 className="App-title">ANIMUS</h1>
          <p className="App-mission">Bringing the artists to center stage.<br />Always thinking, always adapting.</p>
        </div>
      </section>
      {/*}
    <section className="App-intro" style={{backgroundImage: `url('animus-bg-static.jpg')`}}> </section>
    {*/}

      <div className="top-section-group" style={{ backgroundImage: `url('web-bg-bw.jpg')` }}>
        <section id="about" className="App-about">
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-middle">
              <Cell small={12} medium={5} large={5}>
                <img alt="Animus Logo" src={'animus-logo-3d--lighter.png'} />
              </Cell>
              <Cell small={12} medium={7} large={7}>
                <h2>Our Mission</h2>
                <p>
                  Animus is a collective of artists dedicated to bridging the divide between the traditional and digital art worlds.
                  By harnessing the benefits of web2 and web3 technologies, we foster meaningful connections within our community and encourage exploration and experimentation.
                  Our aim is to push the limits of art and provide a platform for artists to showcase their unique talents and perspectives.
                </p>
                <span>Made by Animus - built by the artist.</span>
              </Cell>
            </Grid>
          </div>
        </section>
        <section id="pass" className="App-access">
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-middle">
              <Cell small={12} medium={7} large={6}>
                <h2>Key Card</h2>
                <p className="larger">This card serves as a multi-functional membership.</p>
                <p>By holding it, you will gain access to all allow lists through Animus. This includes collaborative drops, spotlight artists' releases, events, and limited edition merch.</p>
                <span>As the Animus ecosystem grows, so does the card's access.</span>
              </Cell>
              <Cell small={12} medium={5} large={5}>
                <img alt="Animus Access Card" src={'animus-cards-3.png'} />
              </Cell>
            </Grid>
          </div>
        </section>
      </div>
      <section id="mint" className="App-mint" style={{ backgroundImage: `url('animus-mint-bg-2.jpg')` }}>
        <div className="grid-container">
          <Grid className="grid-x grid-padding-x align-middle">
            <Cell small={12} medium={7} large={7}></Cell>
            <Cell small={12} medium={5} large={5}>
              <div className="App-mint-slate" style={{ backgroundImage: `url('mint-slate-1.png')` }}>
                <div className="App-mint-slate__inner">
                  <h2>Mint</h2>
                  <div className="mint-stat mint-stat--price">
                    <span className="mint-stat__label">Price</span>
                    <div className="mint-stat__amount">0.111 ETH</div>
                  </div>
                  <div className="mint-stat mint-stat--supply">
                    <span className="mint-stat__label">Supply</span>
                    <div className="mint-stat__amount">1111</div>
                  </div>
                  <div className="mint-buy">
                    <div
                      id='rampp-minting-container-7f4b659b-aa17-4cf6-8571-6e0ecab6a5fd'
                      className='rampp-minting-container'>
                      <button
                        id='rampp-minting-button-7f4b659b-aa17-4cf6-8571-6e0ecab6a5fd'
                        className='rampp-minting-button mint-buy__button'
                        style={{ display: 'none' }}
                        data-merkle-proof-uri='https://us-central1-nft-rampp.cloudfunctions.net/allowlist/TBmkbpRLAkyrPuCBMDYT/merkle/verify'
                        data-styles="eyJvcGVuIjp7InRleHQiOiJNSU5UIiwic3R5bGVzIjoiYm9yZGVyOm5vbmU7d2lkdGg6IDE1cmVtO3BhZGRpbmc6MC41cmVtO2ZvbnQtc2l6ZTogMS4xMjVyZW07bGluZS1oZWlnaHQ6IDEuNzVyZW07dGV4dC1hbGlnbjogY2VudGVyO2N1cnNvcjogcG9pbnRlcjtib3JkZXItcmFkaXVzOjAuMzc1cmVtO2NvbG9yOiNGRkZGRkY7YmFja2dyb3VuZC1jb2xvcjojNTMyNGZmOyJ9LCJwYXVzZWQiOnsidGV4dCI6IlNPTEQlMjBPVVQiLCJzdHlsZXMiOiJib3JkZXI6bm9uZTt3aWR0aDogMTVyZW07cGFkZGluZzowLjVyZW07Zm9udC1zaXplOiAxLjEyNXJlbTtsaW5lLWhlaWdodDogMS43NXJlbTt0ZXh0LWFsaWduOiBjZW50ZXI7Y3Vyc29yOiBwb2ludGVyO2JvcmRlci1yYWRpdXM6MC4zNzVyZW07Y29sb3I6IzY3NjU2NTtiYWNrZ3JvdW5kLWNvbG9yOiNDQ0RCREM7Y3Vyc29yOm5vdC1hbGxvd2VkOyJ9LCJzdXBwbHlSZWFjaGVkIjp7InRleHQiOiJBbGwlMjBUb2tlbnMlMjBNaW50ZWQhIiwic3R5bGVzIjoiYm9yZGVyOm5vbmU7d2lkdGg6IDE1cmVtO3BhZGRpbmc6MC41cmVtO2ZvbnQtc2l6ZTogMS4xMjVyZW07bGluZS1oZWlnaHQ6IDEuNzVyZW07dGV4dC1hbGlnbjogY2VudGVyO2N1cnNvcjogcG9pbnRlcjtib3JkZXItcmFkaXVzOjAuMzc1cmVtO2NvbG9yOiNmZjAwMDA7YmFja2dyb3VuZC1jb2xvcjojZmZiOGI4O2N1cnNvcjpub3QtYWxsb3dlZDsifSwiZXJyb3IiOnsidGV4dCI6Ik1pbnRpbmcgVW5hdmFpbGFibGUiLCJzdHlsZXMiOiJib3JkZXI6bm9uZTt3aWR0aDogMTVyZW07cGFkZGluZzowLjVyZW07Zm9udC1zaXplOiAxLjEyNXJlbTtsaW5lLWhlaWdodDogMS43NXJlbTt0ZXh0LWFsaWduOiBjZW50ZXI7Y3Vyc29yOiBwb2ludGVyO2JvcmRlci1yYWRpdXM6MC4zNzVyZW07Y29sb3I6I2ZmMDAwMDtiYWNrZ3JvdW5kLWNvbG9yOiNmZmI4Yjg7Y3Vyc29yOm5vdC1hbGxvd2VkOyJ9LCJjbGFpbVRleHQiOnsidGV4dCI6bnVsbCwic3R5bGVzIjoiY29sb3I6IHJnYmEoMTU2LCAxNjMsIDE3NSk7Zm9udC1zaXplOiAwLjc1cmVtO2xpbmUtaGVpZ2h0OiAxcmVtO3RleHQtYWxpZ246IGNlbnRlcjtwYWRkaW5nLXRvcDogMC4yNXJlbTtwYWRkaW5nLWJvdHRvbTogMC4yNXJlbTttYXJnaW46MDtmb250LWZhbWlseTpzYW5zLXNlcmlmOyJ9LCJxdWFudGl0eSI6eyJ0ZXh0IjpudWxsLCJzdHlsZXMiOiJ3aWR0aDo0MHB4O2NvbG9yOiM1MzI0ZmY7Ym9yZGVyLXN0eWxlOnNvbGlkO2JvcmRlci13aWR0aDoxcHg7Ym9yZGVyLWNvbG9yOiM1MzI0ZmY7Ym9yZGVyLXJhZGl1czowLjM3NXJlbTtmb250LXNpemU6MS4zcmVtO3RleHQtYWxpZ246Y2VudGVyOyJ9LCJtaW50TWVzc2FnZSI6IkJ5IG1pbnRpbmcsIHlvdSdyZSBub3Qgb25seSBvYnRhaW5pbmcgdGhlIEFuaW11cyBLZXkgQ2FyZCwgYnV0IGJlY29taW5nIGEgcGFydCBvZiB0aGUgQW5pbXVzIGZhbWlseS4ifQ=="
                        data-abi-link="https://firebasestorage.googleapis.com/v0/b/nft-rampp.appspot.com/o/solidity_outputs%2FTBmkbpRLAkyrPuCBMDYT%2FAnimusContract_data-a98eeb9c-fa80-4bb7-b799-de4bfe4e316b.json?alt=media"
                        data-token-id="1"
                        data-redirect="null"
                        data-contract-address="0xCd5207Fa8779B451a1AB109617c3584BC4510590"
                        data-show-claim-count="true"
                        data-redemption-contracts=""
                        data-network="arbitrum"
                        data-format="single">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Cell>
          </Grid>
        </div>
      </section>
      <section className="App-video" style={{ backgroundImage: `url('web-bg.jpg')` }}>
        <div className="grid-container">
          <Grid className="grid-x grid-padding-x align-center">
            <Cell small={12}>
              <div className="video-slate" style={{ backgroundImage: `url('video-slate-1.png')` }}>
                <div className="video-slate__inner">
                  <div className="video-slate__play" style={{ backgroundImage: `url('play.svg')` }} onClick={() => setOpen(true)}></div>
                  <img alt="Video Background" src={'video-slate-inner-1.jpg'} />
                  <React.Fragment>
                    <ModalVideo channel='vimeo' autoplay isOpen={isOpen} videoId="749573499" onClose={() => setOpen(false)} />
                  </React.Fragment>
                </div>
              </div>
            </Cell>
          </Grid>
        </div>
      </section>
      <section className="App-museum" style={{ backgroundImage: `url('webmuseum.jpg')` }}>
        <div className="grid-container">
          <Grid className="grid-x grid-padding-x align-center">
            <Cell small={12} medium={12}>
              <h2>The <br />Museum</h2>
              <p>The ANIMUS MUSEUM serves as a custodian for all Web3 Art to echo in digital posterity.</p>
              <div className="museum-slate" style={{ backgroundImage: `url('museum-slate-bg-4.png')` }}>
                <div className="museum-slate__inner">
                  <p>The Animus Museum contains several wings which will display collected works from various artists in Web3, as well as, a dedicated section for exhibitions from the Animus community.</p>
                </div>
              </div>
            </Cell>
            <Cell small={12} medium={6}></Cell>
          </Grid>
        </div>
      </section>
      <div id="merch" className="bottom-section-group" style={{ backgroundImage: `url('web-bg-bw.jpg')` }}>
        <section className="App-merch">
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-center">
              <Cell small={12} medium={14}>
                <h2>Merch</h2>
              </Cell>
            </Grid>
            <Grid className="grid-x grid-padding-x align-center">
              <Cell small={12} medium={4}>
                <a href="https://shop.madebyanimus.com/products/animus-og">
                  <div className="App-merch__tile" style={{ backgroundImage: `url('merch-slate.png')` }}>
                    <div className="App-merch__coming-soon"><span>AVAILABLE</span></div>
                    <div className="App-merch__tile-info">
                      <img alt="Animus OG Shirt" src={'merch--animus-og.png'} />
                      <h4>Animus OG TEE</h4>
                    </div>
                  </div>
                  <div className="App-merch__tile-glow"></div>
                </a>
              </Cell>
              <Cell small={12} medium={4}>
                <a href="https://shop.madebyanimus.com/products/al-grime">
                  <div className="App-merch__tile" style={{ backgroundImage: `url('merch-slate.png')` }}>
                    <div className="App-merch__coming-soon"><span>SALE ENDED</span></div>
                    <div className="App-merch__tile-info">
                      <img alt="Animus AL Grime Shirt" src={'merch--algrime.png'} />
                      <h4>Imagine</h4>
                    </div>
                  </div>
                </a>
                <div className="App-merch__tile-glow"></div>
              </Cell>
              <Cell small={12} medium={4}>
                <a href="https://shop.madebyanimus.com/products/animus-og-pin">
                  <div className="App-merch__tile" style={{ backgroundImage: `url('merch-slate.png')` }}>
                    <div className="App-merch__coming-soon"><span>AVAILABLE</span></div>
                    <div className="App-merch__tile-info">
                      <img alt="Animus OG Pin" src={'merch--animus-og-pin.png'} />
                      <h4>ANIMUS OG PIN</h4>
                    </div>
                  </div>
                  <div className="App-merch__tile-glow"></div>
                </a>
              </Cell>
            </Grid>
          </div>
       </section>
       <section id="imaginari" className="App-claim" style={{ backgroundImage: `url('imaginari-main-bg.webp')` }}>
  <div className="grid-container">
    <Grid className="grid-x grid-padding-x align-center align-middle">
      <Cell small={12} medium={7} large={7}></Cell>
      <Cell small={12} medium={5} large={5}>
        <div className="App-claim-slate">
          <div className="App-claim-slate__inner">
            <h2></h2>
            <div className="claim-buy">
              <button className="claim-buy__button" onClick={() => window.location.href = 'https://imaginari.madebyanimus.com'}>
                <span>ENTER</span>
              </button>
            </div>
          </div>
        </div>
      </Cell>
    </Grid>
  </div>
</section>

    <section id="team" className="App-team">
        <div className="grid-container">
          <Grid className="grid-x grid-padding-x align-center">
            <Cell small={12} medium={8}>
                  <h2>Team</h2>
                  <p>In the two years of Animus’ presence in the Web3 space, its core mission and greatest accomplishment has been propelling artists in our community to new heights in their artistic careers.</p>
                </Cell>
              </Grid>
              <Grid className="grid-x grid-padding-x align-center">
                <Cell small={6} medium={4}>
                  <div className="App-team__tile">
                    <div className="App-team__tile-info">
                      <img alt="JN Silva" src={'jnsilva.png'} />
                      <h3>JN Silva</h3>
                      <h4>Founder</h4>
                      <div className="App-team__social">
                        <a target="_blank" rel="noreferrer" href="https://twitter.com/JNSilva_"><img alt="Twitter" src={'twitter.svg'} /></a>
                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/jnsilva/"><img alt="Instagram" src={'instagram.svg'} /></a>
                      </div>
                    </div>
                  </div>
                  <div className="App-team__tile-glow"></div>
                </Cell>
                <Cell small={6} medium={4}>
                  <div className="App-team__tile">
                    <div className="App-team__tile-info">
                      <img alt="Phil Yoon" src={'phil-yoon-2.png'} />
                      <h3>Phil Yoon</h3>
                      <h4>Co-Founder</h4>
                      <div className="App-team__social">
                        <a target="_blank" rel="noreferrer" href="https://twitter.com/philyoon_"><img alt="Twitter" src={'twitter.svg'} /></a>
                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/phil.yoon/"><img alt="Instagram" src={'instagram.svg'} /></a>
                      </div>
                    </div>
                  </div>
                  <div className="App-team__tile-glow"></div>
                </Cell>
                <Cell small={6} medium={4}>
                  <div className="App-team__tile">
                    <div className="App-team__tile-info">
                      <img alt="Jon Suarez" src={'jon-suarez.png'} />
                      <h3>Jon Suarez</h3>
                      <h4>Partner</h4>
                      <div className="App-team__social">
                        <a target="_blank" rel="noreferrer" href="https://twitter.com/heresjonny"><img alt="Twitter" src={'twitter.svg'} /></a>
                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/jmsuarez_"><img alt="Instagram" src={'instagram.svg'} /></a>
                      </div>
                    </div>
                  </div>
                  <div className="App-team__tile-glow"></div>
                </Cell>
                <Cell small={6} medium={4}>
                  <div className="App-team__tile">
                    <div className="App-team__tile-info">
                      <img alt="Seandshoots" src={'sean-dougherty-2.png'} />
                      <h3>Seandshoots</h3>
                      <h4>Partner</h4>
                      <div className="App-team__social">
                        <a target="_blank" rel="noreferrer" href="https://twitter.com/seandtweets"><img alt="Twitter" src={'twitter.svg'} /></a>
                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/seandshoots/"><img alt="Instagram" src={'instagram.svg'} /></a>
                      </div>
                    </div>
                  </div>
                  <div className="App-team__tile-glow"></div>
                </Cell>
                <Cell small={6} medium={4}>
                  <div className="App-team__tile">
                    <div className="App-team__tile-info">
                      <img alt="mrez" src={'mrez.png'} />
                      <h3>Sylvio Sandino</h3>
                      <h4>Partner</h4>
                      <div className="App-team__social">
                        <a target="_blank" rel="noreferrer" href="https://twitter.com/REZ_RNDR"><img alt="Twitter" src={'twitter.svg'} /></a>
                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/sylviosandino/"><img alt="Instagram" src={'instagram.svg'} /></a>
                      </div>
                    </div>
                  </div>
                  <div className="App-team__tile-glow"></div>
                </Cell>
              </Grid>
            </div>
          </section>
        </div>
        <><section className="App-community" style={{ backgroundImage: `url('animus-footer.jpg')` }}>
          <div className="grid-container">
            <Grid className="grid-x grid-padding-x align-center">
              <Cell small={10} medium={6}>
                <h2>Community</h2>
                <div className="App-community__social">
                  <a target="_blank" rel="noreferrer" href="https://twitter.com/madebyanimus">
                    <img alt="Twitter" src={'twitter.svg'} />
                  </a>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/madebyanimus/">
                    <img alt="Instagram" src={'instagram.svg'} />
                  </a>
                </div>
              </Cell>
            </Grid>
          </div>
        </section><footer className="App-footer">
            <Grid className="grid-x grid-padding-x align-center">
              <Cell small={12} medium={3}>
                <span>COPYRIGHT 2023 © MADE BY ANIMUS INC.</span>
              </Cell>
              <Cell small={12} medium={3}></Cell>
              <Cell small={12} medium={6}>
                <a href="#">TERMS & CONDITIONS</a>
                <a href="#">PRIVACY POLICY</a>
                <a href="#">SHIPPING & RETURNS</a>
              </Cell>
            </Grid>
          </footer></>
      </div>
  );
};

export default App;
